

















































































































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import OrderApi, { CreateOrderInput } from "@/services/api/order";
import { AxiosError, AxiosResponse } from "axios";
import ErrorService, { ValidationErrors } from "@/services/errors";
import Order from "@/models/order";
import SmartcardApi from "@/services/api/smartcard";
import Smartcard from "@/models/smartcard";
import {
    mdiAlert,
    mdiCheck,
    mdiChevronDown,
    mdiChevronRight,
    mdiContentCopy,
    mdiTelevisionClassic,
} from "@mdi/js";
import InternalLink from "@/components/InternalLink.vue";
import { SelectItem } from "@/types/veutify";
import { Action, Getter } from "vuex-class";
import { User } from "@/models/user";
import UserApi from "@/services/api/user";
import PriceApi from "@/services/api/prices";
import firebase, {
    addAnalyticsEvent,
    ensureAuthenticated,
} from "@/plugins/firebase";
import { captureSentryException, captureSentryMessage } from "@/plugins/sentry";
import StatusCodes from "http-status-codes";
import CompositeAlert from "@/components/CompositeAlert.vue";
import { getDstvPackagesAsSelectItem } from "@/models/dstv-package";
import UserProfile from "@/models/user-profile";
import { isWebView } from "../../plugins/utils";

@Component({
    components: {
        CompositeAlert,
        InternalLink,
        Link: InternalLink,
    },
    metaInfo() {
        let image = "https://nyangapay.com/img/header-1.png";
        if (window.location.href.indexOf("invite=") != -1) {
            image = "https://nyangapay.com/img/invite-header.png";
        }

        let title =
            "Pay your DStv subscription online using MTN Mobile Money or Orange Money";
        if (window.location.href.indexOf("invite=") != -1) {
            title = "Get 1,000 FCFA discount for your first DStv subscription";
        }

        let description =
            "Pay your DStv subscription online using MTN Mobile Money or Orange Money";
        if (window.location.href.indexOf("invite=") != -1) {
            description =
                "Use this link to get 1,000 FCFA discount for your first DStv subscription on NyangaPay.com using Orange Money or Mobile Money";
        }

        return {
            title,
            meta: [
                {
                    name: "og:image",
                    content: image,
                },
                {
                    name: "twitter:image",
                    content: image,
                },
                {
                    name: "og:title",
                    content: title,
                },
                {
                    name: "twitter:title",
                    content: title,
                },
                {
                    name: "og:description",
                    content: description,
                },
                {
                    name: "twitter:description",
                    content: description,
                },
                {
                    name: "description",
                    content: description,
                },
            ],
        };
    },
})
export default class HomeIndex extends Vue {
    formEmail: string = "";
    referralPanel: Array<number> = [];
    chevronRight: string = mdiChevronRight;
    copyIcon: string = mdiContentCopy;
    chevronDown: string = mdiChevronDown;
    tickIcon: string = mdiCheck;
    alertIcon: string = mdiAlert;
    formSmartCardNumber: string = "";
    formPaymentOption: string = "";
    formPhoneNumber: string = "";
    tvIcon: string = mdiTelevisionClassic;
    formReferralCode: string = "";
    formDstvPackage: string = "CB177-new";
    formCostPrice: number = 50;
    formSalePrice: number = 100;
    formNewDstvPackageID: string = "CB177-new";
    formPriceLock = false;
    formNameConfirmCheckbox: boolean = false;
    formInputErrors: ValidationErrors = new ValidationErrors();
    isSubmitting: boolean = false;
    isValidatingSmartcardNumber = false;
    smartcard: Smartcard | null = null;
    smartCardNumberIsValid = false;
    dstvPackages: Array<SelectItem> = [];
    formMonths = 1;
    profileSmartCardNumber: string | null = null;

    @Getter("user") user!: User | null;
    @Getter("userIsAnonymous") userIsAnonymous!: boolean;
    @Getter("authToken") authToken!: string;
    @Getter("userIsAdmin") userIsAdmin!: boolean;
    @Getter("referralCode") referralCode!: string;
    @Action("setUser") setUser!: (user: User | null) => void;
    @Action("setAuthToken") setAuthToken!: (authToken: string | null) => void;

    isNotAffectedByPriceLock(packageID: string): boolean {
        return packageID.includes("TEST") || packageID.includes("CUSTOM");
    }

    get monthOptions(): Array<SelectItem> {
        return [...Array(12).keys()].map((key): SelectItem => {
            return {
                value: key + 1,
                text: key + 1 + " Month" + (key > 0 ? "s" : ""),
            };
        });
    }

    get isWebView(): boolean {
        return isWebView();
    }

    get payUsingBankCard(): boolean {
        return this.formPaymentOption == "bank-card";
    }

    get formPhoneNumberLabel(): string {
        if (!this.formPaymentOption) {
            return "Payment Phone Number";
        }

        if (this.formPaymentOption == "mtn-mobile-money") {
            return "MTN Mobile Money Phone Number";
        }
        return "Orange Money Phone Number";
    }

    get formPhoneNumberPlaceholder(): string {
        if (!this.formPaymentOption) {
            return "Phone number e.g 6xxxxxxxx";
        }
        return `${this.paymentMethodString} number e.g 6xxxxxxxx`;
    }

    get paymentMethodString(): string {
        return this.$options.filters?.capitalize(
            this.formPaymentOption?.split("-").join(" ")
        );
    }

    get isCustomPackage(): boolean {
        return this.formDstvPackage === "CUSTOM";
    }

    get activeDStvPackages(): Array<SelectItem> {
        if (this.formPriceLock) {
            return this.dstvPackages.filter(
                (item) =>
                    !item.value.toString().includes("-new") ||
                    this.isNotAffectedByPriceLock(item.value as string)
            );
        }

        return this.dstvPackages.filter(
            (item) =>
                item.value.toString().includes("-new") ||
                this.isNotAffectedByPriceLock(item.value as string)
        );
    }

    get newDstvPackages(): Array<SelectItem> {
        return this.activeDStvPackages.filter(
            (item) => !this.isNotAffectedByPriceLock(item.value as string)
        );
    }

    validateSmartcardNumber() {
        this.isValidatingSmartcardNumber = true;
        this.formNameConfirmCheckbox = false;
        this.smartcard = null;
        this.smartCardNumberIsValid = false;

        this.$recaptcha("validate_smartcard")
            .then((token: string) => {
                SmartcardApi.create(this.formSmartCardNumber, token)
                    .then((response: AxiosResponse) => {
                        this.formInputErrors.clear("smartcard_number");
                        this.smartcard = new Smartcard(response.data.data);
                        this.smartCardNumberIsValid = true;

                        if (
                            this.smartCardNumberIsValid &&
                            this.formSmartCardNumber ===
                                this.profileSmartCardNumber
                        ) {
                            this.formNameConfirmCheckbox = true;
                        }
                    })
                    .catch(this.handleAxiosError)
                    .finally(() => {
                        this.isValidatingSmartcardNumber = false;
                    });
            })
            .catch((error) => {
                this.isValidatingSmartcardNumber = false;
                this.handleError(error);
            });
    }

    @Watch("formPriceLock")
    onFormPriceLockChanged(priceLocked: boolean) {
        addAnalyticsEvent("price_lock_changed", { priceLocked });
        if (priceLocked) {
            this.formDstvPackage = this.formDstvPackage.replace("-new", "");
            return;
        }
        if (this.isNotAffectedByPriceLock(this.formDstvPackage)) {
            return;
        }
        this.formDstvPackage =
            this.formDstvPackage.replace("-new", "") + "-new";
    }

    get dstvPackageExists(): boolean {
        return (
            this.activeDStvPackages.findIndex(
                (x) => x.value === this.formDstvPackage
            ) !== -1
        );
    }

    get packageHasExtraView(): boolean {
        const dstvPackage = this.dstvPackages.find(
            (x) => x.value === this.formDstvPackage
        );
        if (dstvPackage) {
            return dstvPackage.text.toLowerCase().includes("+ xtra view");
        }
        return false;
    }

    paySubscription() {
        addAnalyticsEvent("pay_subscription_clicked");
        this.isSubmitting = true;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const handleError = (error: any) => {
            this.isSubmitting = false;
            this.handleError(error);
        };

        this.$recaptcha("submit_order")
            .then((token: string) => {
                const payload: CreateOrderInput = {
                    email: this.formEmail,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    smartcard_number: this.formSmartCardNumber,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    payment_method: this.formPaymentOption,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    phone_number: this.formPhoneNumber,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    dstv_package: this.dstvPackageExists
                        ? this.formDstvPackage
                        : "",
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    sale_price: this.isCustomPackage
                        ? parseInt(this.formSalePrice.toString())
                        : null,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    cost_price: this.isCustomPackage
                        ? parseInt(this.formCostPrice.toString())
                        : null,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    referral_code: this.formReferralCode,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    new_dstv_package_id: this.formNewDstvPackageID,
                    captcha: token,
                    months: this.formMonths,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    name_confirmation: this.formNameConfirmCheckbox,
                };
                this.ensureUserExists()
                    .then(() => {
                        this.createOrder(payload);
                    })
                    .catch(handleError);
            })
            .catch(handleError);
    }

    setProfileFromAuthUser() {
        this.formEmail = this.user?.email || "";
    }

    loadProfile() {
        if (!firebase.auth().currentUser?.uid) {
            captureSentryException(
                "cannot load profile for an unauthenticated user"
            );
            return;
        }

        UserApi.getProfile(firebase.auth().currentUser?.uid as string)
            .then((response: AxiosResponse) => {
                const userProfile = new UserProfile(response.data.data);
                this.formDstvPackage =
                    userProfile.dstvPackageID ?? this.formDstvPackage;
                this.formPaymentOption = userProfile.paymentMethod ?? "";
                this.formPhoneNumber = userProfile.paymentPhoneNumber ?? "";
                this.formEmail = userProfile.email ?? this.user?.email ?? "";
                this.profileSmartCardNumber = userProfile.smartcardNumber;

                if (this.formSmartCardNumber.length !== 10) {
                    this.formSmartCardNumber =
                        userProfile.smartcardNumber ?? "";
                }

                if (this.formSmartCardNumber) {
                    setTimeout(
                        () => {
                            this.validateSmartcardNumber();
                        },
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        (this.$recaptcha as any) ? 1000 : 5000
                    );
                }
            })
            .catch((error: AxiosError) => {
                if (error.response?.status === StatusCodes.NOT_FOUND) {
                    this.setProfileFromAuthUser();
                    return;
                }
                this.handleAxiosError(error);
            });
    }

    loadPrices() {
        PriceApi.indexDstv()
            .then((response: AxiosResponse) => {
                this.dstvPackages = response.data.data;
            })
            .catch(captureSentryException);
    }

    loadReferralCode() {
        setTimeout(() => {
            this.formReferralCode = this.referralCode;
        }, 1000);
    }

    async copySmartcardName() {
        try {
            addAnalyticsEvent("copy_surname_clicked");
            await navigator.clipboard.writeText(
                this.smartcard?.customerName ?? ""
            );
            this.$root.$emit(
                this.$constants.NOTIFICATION_EVENTS.SUCCESS,
                `"${this.smartcard?.customerName}" has been copied to your clipboard`
            );
        } catch (error) {
            captureSentryException(error);
        }
    }

    mounted() {
        if (typeof this.$route.query.sc === "string") {
            this.formSmartCardNumber = this.$route.query.sc.trim();
            addAnalyticsEvent("smartcard_number_in_url", {
                smartcard: this.formSmartCardNumber,
            });
        }
        this.dstvPackages = getDstvPackagesAsSelectItem(this.userIsAdmin);
        this.loadPrices();
        this.loadReferralCode();
        if (this.user || this.userIsAnonymous) {
            ensureAuthenticated()
                .then(() => {
                    this.loadProfile();
                })
                .catch(this.handleError);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleError(error: any) {
        if (error instanceof Error && error.message) {
            captureSentryException(error);
            this.$root.$emit(
                this.$constants.NOTIFICATION_EVENTS.ERROR,
                error.message
            );
            return;
        }

        captureSentryMessage(error);
        this.$root.$emit(
            this.$constants.NOTIFICATION_EVENTS.ERROR,
            "We could not carry out your request. It may be because your internet connection is not so good. Please try again"
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ensureUserExists(): Promise<any> {
        if (this.authToken) {
            return new Promise<boolean>((resolve) => resolve(true));
        }
        return firebase
            .auth()
            .signInAnonymously()
            .then(async (response) => {
                this.setUser(response.user);
                this.setAuthToken((await response.user?.getIdToken()) || null);
                addAnalyticsEvent("anonymous_user_created_before_order");
            });
    }

    createOrder(payload: CreateOrderInput) {
        OrderApi.create(payload)
            .then((response: AxiosResponse) => {
                const order = new Order(response.data.data);
                this.$root.$emit(
                    this.$constants.NOTIFICATION_EVENTS.SUCCESS,
                    "Your order has been created successfully"
                );

                addAnalyticsEvent("begin_checkout", {
                    currency: "XAF",
                    value: order.paymentAmount,
                    coupon: order.referralCode,
                    items: [
                        {
                            // eslint-disable-next-line @typescript-eslint/camelcase
                            item_id: order.itemId,
                            // eslint-disable-next-line @typescript-eslint/camelcase
                            item_name: order.itemName,
                            affiliation: "DStv",
                            coupon: order.referralCode,
                            currency: "XAF",
                        },
                    ],
                });

                this.$router.push({
                    name: this.$constants.ROUTE_NAMES.ORDERS_SHOW,
                    params: {
                        orderId: order.orderId,
                    },
                });
            })
            .catch(this.handleAxiosError)
            .finally(() => {
                this.isSubmitting = false;
            });
    }

    handleAxiosError(error: AxiosError) {
        this.formInputErrors = ErrorService.getValidationErrors(error);
        this.handleError(
            new Error(error?.response?.data?.message ?? error.message)
        );
    }
}
