






import { Vue, Component, Prop } from "vue-property-decorator";
import { Location } from "vue-router";

@Component
export default class InternalLink extends Vue {
    @Prop({ required: true }) name!: string;
    @Prop({ required: false, default: false }) disabled!: boolean;
    get location(): Location {
        return {
            name,
        };
    }
}
